import React, { useState,useEffect } from 'react'
import './Members.css'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import axios from 'axios';
import { adminInactiveMemberList } from '../../AllApi/AdminApi/AdminApi';
import { Row, Table } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';


const InactiveMembers = () => {

// ----------------------------------------------------------------
  const [record, setRecord] = useState([])  

  //----------------------------------API code------------------------------
  useEffect(() => {
    const tokenValue = sessionStorage.getItem('token')

    const tokenConfig = {
      headers: {
        token: tokenValue,
      },
    }

    const fetchMemberData = async () => {
      try {
        const response = await axios.get(
          adminInactiveMemberList,
          tokenConfig);
          console.log(response.data)
        setRecord(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  fetchMemberData()
}, [])


 //=====================================DATA TABLE============================================
 const footerStyle = {
  backgroundColor: '#660505',
  boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
  padding: '8px',
  textAlign: 'center',
  color: '#fff',
  fontSize: '12px',
  bottom: 0,
  width: '100%',
  letterSpacing: '1px',
}

 const columns = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Member Id',
    field: 'member_id',
    sort: 'asc',
  },
  {
    label: 'Name',
    field: 'Name',
    sort: 'asc',
  },
  {
    label: 'Gender',
    field: 'gender',
    sort: 'asc',
  },
  {
    label: 'Mobile No',
    field: 'mobileno',
    sort: 'asc',
  },
  {
    label: 'Email Id',
    field: 'email_id',
    sort: 'asc',
  },
  
  
];


const customRows = record.map((item, index) => {
  const { gender, Name, mobileno, email_id, member_id, } = item;
  const genderLabel = gender === 1 ? 'Male' : 'Female';
  return {
    srNo: index + 1,
    member_id,
    Name,
    gender:genderLabel,
    mobileno,
    email_id,
  };
});

//==================================================================================

  return (
 
     <>
    <div className="row m-0">
      <div className='p-0'> <AdminMasterHeader/> </div>
      <div className="filter-buttons row">

        <div className='col-12'>
        <h2 style={{ fontWeight: '550',textAlign:"start"}}>Inactive Members</h2>
        </div>
        </div>
{/*       
      <Row className='row p-0 m-0 mb-5'>
      <div className='col-1'></div>
      <div  className='col-10'>
      <Table striped bordered hover size='sm' className='photoApprove shadow' style={{fontSize:"14px"}}>
      <thead>
      <tr className='text-center'>
          <th>Sr.No</th>
          <th>Name</th>
          <th>Gender</th>
          <th>Mobile</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
      {record.map((member,index) => (
        <tr className="text-center" key={index}>

          <td>{index + 1}</td>
          <td>{member.member_name}</td>
          <td>{member.gender === 1 ? 'Male' : 'Female'}</td>
          <td>{member.mob_no}</td>
          <td>{member.email}</td>
        </tr>
        ))}
        </tbody>
        </Table>
      </div>
      <div className='col-1'></div>
     </Row> */}

     <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
        <div className="col-12 col-sm-1"></div>
        <div className="col-12 col-sm-10" style={{overflowX:"auto"}}>
        
            <MDBDataTable
              className='mb-5 custom-datatable'
              striped
              bordered
              hover
              small
              data={{ columns, rows: customRows }}
              style={{ textAlign: "center", fontSize: "14px",}}/>
        </div>
        <div className="col-12 col-sm-1"></div>
    </Row>

    </div>
    <div style={footerStyle} className='mt-5 fixed-bottom'>
        &copy; 2024-25 Jain Boarding | Managed By : TechnoAarv Solution
      </div>
    </>
  )
}

export default InactiveMembers