import React, { useEffect, useState } from 'react';
import UserMasterHeader from './UserCommonComponent/UserMasterHeader';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import afterlogo from '../assets/login-after.png';
import QRlogo from '../assets/QRpayment.jpeg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { RegistrationSelect, UserPaymentAdd } from '../AllApi/UserApi/UserApi';
import UserMasterFooter from './UserCommonComponent/UserMasterFooter';

const PaymentNew = () => {
  
  useEffect(() =>{
    window.scrollTo(0, 0);
  },[])
  
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const navigate = useNavigate()
  const data = JSON.parse(sessionStorage.getItem("status"))
  const [Image, setImage] = useState(null);
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const m_id = userdata.id
  const [transactionId, setTransactionId] = useState()
  const [amount, setAmount] = useState(1200);
  // const [pay_mode, setPay_mode] = useState(1);

  const validateFileSize = (file, maxSizeMB, errorMessage) => {
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
      if (fileSizeInMB > maxSizeMB) {
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return false;
      }
    }
    return true;
  };

const logoutFun = () => {
  sessionStorage.removeItem("userdata");
  sessionStorage.clear()
  navigate("/");
}

  const NewData = (e) => {
    e.preventDefault();
    if (!Image) {
      toast.error("Please select a photo before submitting.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    setIsLoading(true);

    if (!validateFileSize(Image, 1, 'Image size exceeds the maximum limit of 1MB.')) {
      setIsLoading(false);
      return;
    }

    let formData = new FormData();
    const status = "0"
    formData.append("file", Image);
    formData.append("member_id", m_id);
    formData.append("taxid", transactionId);
    formData.append("pay_amount", amount);
    // formData.append("pay_mod", pay_mode);

    const member_id = formData.get("member_id");
    const taxid = formData.get("taxid");
    const pay_amount = formData.get("pay_amount");
    // const pay_mod = formData.get("pay_mode");
    const file = formData.get("file");
    for (const entry of formData.entries()) {

    }


    const finaldata = {
      member_id: member_id,
      file: file,
      taxid: taxid,
      pay_amount, pay_amount,
      // pay_mod, pay_mod,
    }
    console.log(finaldata);
    
  
// ==========post api===================
    axios
      .post(UserPaymentAdd, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((res) => {
        console.log("///////////////////////===========",res)
        setIsLoading(false);
        if (res.data === "SAVED") {
          toast.success('File Uploaded Successfully! Wait for admin Approval !', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          logoutFun()
        }
        else if(res.data === "ALREADYEXIST"){
          toast.warn('Already Paid!! Wait for Admin approval!!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        else if(res.data === "ERROR"){
          toast.warn('ERROR Occurred!!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        console.log("=====catch block=====",err)
        setIsLoading(false);
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });

  

  };



  const setImgfun = (e) => {
    // setupdateimage(false)
    setImage(e.target.files[0])
  }

//============QR img download====================
const downloadQRCode = () => {
  const link = document.createElement('a');
  link.href = QRlogo;
  link.download = <img src={QRlogo} className='w-80 text-center px-md-3 px-1 mb-3 mb-md-0' height={250} />;
  link.click();
};
//==========================================
  return (
    <Container fluid className='m-0 p-0'>
      <div className='m-0 p-0'>
        <UserMasterHeader />
      </div>

      <Container className='container-fourth rounded p-3 my-3 p-md-5 '>
        <div className=' rounded shadow  px-3 pb-2 py-sm-4' style={{ backgroundColor: "white" }} >
          <Row className='m-0'>
            <Col>
              <div className='mt-3 mt-md-0' style={{ display: "flex", justifyContent: "center", color: "rgb(134, 28, 18)" }}>
                <h5><b >Pay Your Registration Fee</b></h5>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={afterlogo} />
              </div>
            </Col>
          </Row>

          <Row className='m-0 mt-4 mb-4'>
            <Col sm={6} md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <img src={QRlogo} className='w-80 text-center px-md-3 px-1 mb-3 mb-md-0' height={250} />
              <div className="row m-0 p-0 pt-2 text-center">
                <div className="col-6 p-0 text-end">
                   <Form.Label><p className='pt-2' style={{ color: "rgb(134, 28, 18)" }}><b>Pay Amount (₹):</b></p></Form.Label>
                </div>
                <div className="col-6 p-0">
                  <Form.Control
                    className='w-50 text-center'
                    value={amount}
                    readOnly
                  />
              </div>
              </div>
                 <Button className='' style={{background:"rgb(134, 28, 18)",border:"0px"}} onClick= {downloadQRCode}>
                    Download QR Code
                  </Button>
            </Col>

            <Col sm={6} md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                <div>                               
                  <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Account Name :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SHETH RAMDHANAJI DAWADA JAIN BOARDING SANGLI</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Account No :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>120503130002085</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        UPI ID :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>getepay.svccblqr432819@icici</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Bank Name/Branch :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SVC CO-OPERATIVE BANK LTD / SANGLI</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        IFSC Code :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SVCB0000205</p>
                      </Col>
                      </Row>
                </div>

            </Col>

          </Row>

          <Row>
            <Form           
              encType="multipart/form-data">

              <Row className='px-4 mt-md-2'>
                <Col sm={12} md={6}>
                  <Form.Group className="mb-4" controlId="formBasicCheckbox">
                    <Form.Label><h6 style={{ color: "rgb(134, 28, 18)" }}><b>Upload the photo of successful payment receipt  :</b></h6></Form.Label>
                    <Form.Control
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => setImgfun(e)}
                      required
                      name="file"
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                  <Form.Label><h6 style={{ color: "rgb(134, 28, 18)" }}><b>UTR No./UPI No./Transcation Transfer No:</b></h6></Form.Label>
                  <Form.Control
                    sm={12}
                    md={4}
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    required
                  />
                </Col>
              </Row>

              <Row>
              

            <div className="row p-0 m-0">
              <div className="col-md-4"></div>
              <div className="col-md-4" style={{display:"flex",justifyContent:"space-evenly"}}>
                <Button
                  onClick={NewData}
                  style={{
                    display: "flex",
                    backgroundColor: "rgb(134, 28, 18)",
                    border: "none",
                    justifyContent: "center",
                  }}
                  className="my-4 py-2 px-4"
                >
                  <b>Submit</b>
                </Button>
              </div>
              <div className="col-md-4"></div>
            </div>
           


          </Row>


            </Form>
          </Row>

        </div>
      </Container>
      <div><UserMasterFooter /></div>



    </Container>
  )
}

export default PaymentNew
