import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import '../../UserComponents/UserComponent.css';
import "../MemberEntry/MemberEntryCSS.css";
import Card from 'react-bootstrap/Card';
import Logo1 from '../../assets/Acceptable_photos.png';
import Logo2 from '../../assets/unacceptable_photos1v2.png';
import { toast } from 'react-toastify';


import axios from "axios";
import { ClassNames } from '@emotion/react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { RegistrationUpload } from '../../AllApi/AdminApi/AdminApi';
import EntryHeader from '../../CommonComponenets/EntryHeader';
import AdminMasterFooter from '../../CommonComponenets/AdminMasterFooter';

const AdminThirdPage = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const data = JSON.parse(sessionStorage.getItem("status"));
  const [Image, setImage] = useState(null);
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const userid = userdata.member_id;
 
  const validateFileSize = () => {
    if (Image) {
      const fileSizeInMB = Image.size / (1024 * 1024);
      if (fileSizeInMB > 3) {
        toast.error('Image size exceeds the maximum limit of 3MB.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return false;
      }
    }
    return true;
  };


  
  const NewData = (e) => {
    e.preventDefault();
    setIsLoading(true)

    if (!validateFileSize()) {
      setIsLoading(false);
      return;
    }
  
    let formData = new FormData();
    const status="0"
    formData.append("file", Image);
    formData.append("member_id", userid);
  

    const member_id = formData.get("member_id");
    const file = formData.get("file");
   
    
    const finaldata={
      member_id:member_id,
      file:file,
    }
   
    axios
      .post(RegistrationUpload, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((res) => {
        console.log("****************photo upload******************",res)
        setIsLoading(false); 
        if (res.data.message == "UPDATED") {
          toast.success('File Uploaded Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          navigate('/admin/aforthp')
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };

  const [updateimage, setupdateimage] = useState(false)


   
const setImgfun=(e)=>{
  setupdateimage(false)
  setImage(e.target.files[0])
}


  return (
  <>
    
  <div className='m-0'>
  <div className='m-0 p-0'>
        <EntryHeader />
      </div>
  <Container className='container-third3 rounded p-3 my-3 p-md-5 mb-5'>
    <div className=' rounded shadow p-3 pb-2 py-sm-5' style={{ backgroundColor:"white"}} >
      <Row>
      <Form  onSubmit={NewData} encType="multipart/form-data">
      
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Label><h4 style={{color:"rgb(134, 28, 18)"}}><b>Image <span className='text-dark' style={{fontSize:"15px"}}>(Maximum image upload size 3MB)</span></b></h4></Form.Label>
        <Form.Control
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={(e) => setImgfun(e)}
          required
          name="file"
        />
     <div  className='pt-4'>
     <div style={{display:"flex",justifyContent:"center"}}>
        <b style={{color:"rgb(134, 28, 18)"}}>Admin reserves the right to approve/reject the photos (फोटो मंजूर/नाकारणे याचा अधिकार आयोजकांकडे राहील)</b>
     </div>
     <Row className='p-3'>
      <Col sm={12} md={4}>
      <Card className='text-center' style={{ width: '13rem' , border:"0px",height:"12rem" }}>
        <Card.Img variant="top" loading="lazy" src={updateimage ? `nodeApp/image/${Image}`: (Image ? URL.createObjectURL(Image) : '') }  style={{ height: '8rem'}} />
        <Card.Body>
          <Card.Text className='text-center'>
          <b>Uploaded Image </b>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>
    
    
      

      <Col sm={12} md={4}>
       <Card className='both text-center' style={{ width: '12rem', border:"0px" ,height:"12rem" }}>
        <Card.Img variant="top" className='acceptable text-center' src={Logo1} style={{ height: '15rem'}} />
         <Card.Body>
          <Card.Text className='text-center'>
            <b>This is acceptable</b>
            <h1 style={{color:"rgb(0, 247, 0)",fontSize:"50px"}}><b><i class="fa-solid fa-check"></i></b></h1>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>

      
      <Col sm={12} md={4}>
       <Card className='text-center' style={{ width: '13rem' , border:"0px",height:"12rem" }}>
        <Card.Img variant="top" className='unacceptable text-center'  src={Logo2} style={{ height: '8rem'}} />
        <Card.Body>
          <Card.Text className='text-center'>
          <b>This is not acceptable </b>
            <h1 style={{color:"red",fontSize:"50px"}}><b><i class="fa-solid fa-xmark"></i></b></h1>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>
      </Row>
      </div>
      </Form.Group>



{/* ===submit button======= */}

      <div  style={{ display: "flex", justifyContent: "center" }}>
            {isLoading ? (
              <Spinner animation="border" variant="primary" /> // Show Spinner while loading
            ) : (
              <Button 
                type="submit"
                style={{
                  display: "flex",
                  backgroundColor: "rgb(134, 28, 18)",
                  border: "none",
                  justifyContent: "center",
                }}
                className="my-4 py-2 px-4"
              >
                Submit
              </Button>
            )}
          </div>
    </Form>
      </Row>
    </div>
 
  </Container>
  <div className='m-0 p-0'>
        <AdminMasterFooter />
      </div>
  </div>
  </>
  )
}

export default AdminThirdPage




